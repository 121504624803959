import { ArrowLeft, ArrowRight } from '@air/next-icons';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDebouncedCallback } from 'use-debounce';

import { DarkIconButton } from '~/components/AssetModal/shared/components/DarkIconButton';
import {
  ASSET_MODAL_NEXT,
  ASSET_MODAL_NEXT_DISABLED,
  ASSET_MODAL_PREVIOUS,
  ASSET_MODAL_PREVIOUS_DISABLED,
} from '~/constants/testIDs';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { usePublicMediaPlayerData } from '~/swr-hooks/mediaPlayer/usePublicMediaPlayerData';

export const PublicPreviousAndNextArrows = memo(() => {
  const { shortId } = useShortIdContext();
  const { loadMore, nextAsset, previousAsset, isNearEnd } = usePublicMediaPlayerData(shortId);
  const { goToAssetPage } = useGoToAssetPage();
  const { isAboveMediumScreen } = useBreakpointsContext();

  const onPreviousClick = useCallback(() => {
    if (previousAsset) {
      goToAssetPage({
        asset: previousAsset,
      });
    }
  }, [goToAssetPage, previousAsset]);

  const onNextClick = useCallback(() => {
    if (nextAsset) {
      goToAssetPage({
        asset: nextAsset,
      });
      if (isNearEnd) {
        loadMore();
      }
    }
  }, [goToAssetPage, isNearEnd, loadMore, nextAsset]);

  const [delayedOnPrevious] = useDebouncedCallback(onPreviousClick, 100);
  const [delayedOnNext] = useDebouncedCallback(onNextClick, 100);

  useHotkeys(
    'left,right',
    (_keyboardEvent, hotkeysEvent) => {
      if (hotkeysEvent.keys?.includes('left')) {
        delayedOnPrevious();
      } else if (hotkeysEvent.keys?.includes('right')) {
        delayedOnNext();
      }
    },
    [delayedOnNext, delayedOnPrevious, !!nextAsset, !!previousAsset],
  );

  return (
    <div className="pointer-events-none flex w-full shrink-0 items-center justify-start bg-black p-1 md:fixed md:bottom-0 md:left-0 md:bg-transparent md:px-4 md:pb-3 md:pt-0">
      <DarkIconButton
        disabled={!previousAsset}
        icon={ArrowLeft}
        size={isAboveMediumScreen ? 'medium' : 'small'}
        onClick={onPreviousClick}
        testId={!!previousAsset ? ASSET_MODAL_PREVIOUS : ASSET_MODAL_PREVIOUS_DISABLED}
        tx={{
          mr: 'auto',
          pointerEvents: 'all',
        }}
      >
        Previous asset
      </DarkIconButton>
      <DarkIconButton
        disabled={!nextAsset}
        icon={ArrowRight}
        size={isAboveMediumScreen ? 'medium' : 'small'}
        onClick={onNextClick}
        testId={!!nextAsset ? ASSET_MODAL_NEXT : ASSET_MODAL_NEXT_DISABLED}
        tx={{
          ml: 'auto',
          pointerEvents: 'all',
        }}
      >
        Next asset
      </DarkIconButton>
    </div>
  );
});

PublicPreviousAndNextArrows.displayName = 'PublicPreviousAndNextArrows';
